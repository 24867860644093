<template>
    <div v-loading.fullscreen.lock="loading">
        <vacation-search-bar-component
            :AllTypeName="AllTypeName"
            add_auth="新增休假申请"
            @search="get_vacation_index"
            @addTeam="addTeam"
        ></vacation-search-bar-component>
        <common-table-component
            details_auth="休假申请详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_vacation"
            table_height="549px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_vacation_index"
        ></common-page-component>
        <vacation-edit-component
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :vacation_details_data="vacation_details_data"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            print_auth="打印休假申请"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_vacation_index"
            @exitDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_vacation"
        ></vacation-edit-component>
    </div>
</template>

<script>
import { vacation_index_request,vacation_details_request} from '@/network/hrm/vacation.js'
import { vacation_category_list_request} from '@/network/list.js'

import VacationSearchBarComponent from '@/components/hrm/vacation/VacationSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import VacationEditComponent from '@/components/hrm/vacation/VacationEditComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                state: '',
                type: '',
                create_staff: '',
                start_time: '',
                end_time: '',
                page: 1,
                limit: 10,
            },
            AllTypeName:[],
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'type_name',
                    label: '类型',
                    minWidth: '80px',
                },
                {
                    prop: 'name',
                    label: '休假单号',
                    minWidth: '120px'
                },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'duration',
                    label: '休假时长（天）',
                    minWidth: '130px',
                },
                {
                    prop: 'start_time',
                    label: '开始时间',
                    minWidth: '180px',
                },
                {
                    prop: 'end_time',
                    label: '结束时间',
                    minWidth: '180px',
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },
                {
                    prop: 'create_staff_name',
                    label: '申请人',
                    minWidth: '100px',
                }
            ],
            vacation_details_data: {},
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{},
    methods:{
        get_vacation_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.type = param.type ?? this.cond.type
            this.cond.create_staff = param.create_staff ?? this.cond.create_staff
            this.cond.start_time = param.start_time ?? this.cond.start_time
            this.cond.end_time = param.end_time ?? this.cond.end_time
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            vacation_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_vacation_category_list_request(){
             vacation_category_list_request()
             .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllTypeName= s.result
                     
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_vacation(id) {
            this.fid = id
            this.fdialogFormVisible = true
            vacation_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.vacation_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        frefresh() {
            this.details_vacation(this.fid)
            this.get_vacation_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(res.state === 0) {
                //判断创建人是否为自己
                if(res.create_staff === this.$store.state.user.id) {
                    this.to_approval_auth = this.$_has('提交休假申请')
                    this.edit_auth = this.$_has('修改休假申请')
                    this.del_auth = this.$_has('删除休假申请')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if(res.create_staff === this.$store.state.user.id) {
                    this.withdraw_auth = this.$_has('撤回休假申请')
                    this.add_file_auth = this.$_has('补充休假申请附件')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批休假申请')
                }
                this.sel_approval_auth = this.$_has('查看休假申请审批记录')
            } else if(res.state === 2) {
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充休假申请附件')
                }
                this.sel_approval_auth = this.$_has('查看休假申请审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if(res.create_staff === this.$store.state.user.id) {
                    this.to_approval_auth = this.$_has('提交休假申请')
                    this.edit_auth = this.$_has('修改休假申请')
                    this.del_auth = this.$_has('删除休假申请')
                }
                this.sel_approval_auth = this.$_has('查看休假申请审批记录')
            }
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_vacation_index()
        this.get_vacation_category_list_request()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        VacationSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        VacationEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>